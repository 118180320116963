jQuery(function() {

	$galeria = document.querySelector('.galeria-reszletek');
	if ($galeria) {
		var m = new Masonry($galeria, {
			columnWidth: $('.set-grid-size').outerWidth(),
			itemSelector: '.galeria-elem'
		});
	}

	$('.fancybox').fancybox({
		afterLoad: function() {
			this.title+= '<div class="sorszam text-right pull-right" style="display: inline; margin:0; color: white"><span class="color-turkiz">' + (this.index+1) + '</span>/' + $('.fancybox').length + "</div>";
		},
		helpers:  {
			title : {
				type : 'inside',
				position: 'bottom'
			},
			overlay : {
				css : {
					'background' : 'rgba(255,255,255,0.95)'
				}
			},
			thumbs	: {
				width	: 150,
				height	: 150
			}
		},
		nextEffect: 'fade',
		prevEffect: 'fade'
	});

});

function initialize() {

	// Create an array of styles.
	var styles = [
		{
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [
				{ "color": "#2c2c2c" }
			]
		},{
			"featureType": "landscape.natural",
			"elementType": "geometry",
			"stylers": [
				{ "color": "#333333" }
			]
		},{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{ "color": "#2c2c2c" }
			]
		},{
			"featureType": "landscape.man_made",
			"elementType": "geometry",
			"stylers": [
				{ "color": "#333333" }
			]
		},{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{ "color": "#333333" }
			]
		},{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{ "color": "#a9a9a9" },
				{ "visibility": "on" }
			]
		},{
			"featureType": "road",
			"elementType": "labels.text.stroke",
			"stylers": [
				{ "color": "#ffffff" },
				{ visibility: "simplified" }

			]
		},{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{ "color": "#ffffff" },
				{ "visibility": "on" }
			]
		},{
			"featureType": "administrative",
			"elementType": "labels.text.stroke",
			"stylers": [
				{ "color": "#ffffff" },
				{ visibility: "simplified" }

			]
		},{
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
				{ visibility: "off" }

			]
		},{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{ "color": "#244e5d" }
			/*"stylers": [
				{ "lightness": 0 },
				{ "saturation": 00 }*/
			]
		},
		{
			featureType: "poi",
			elementType: "labels",
			stylers: [
				{ visibility: "off" }
			]
		}
	];

	// Create a new StyledMapType object, passing it the array of styles,
	// as well as the name to be displayed on the map type control.
	var styledMap = new google.maps.StyledMapType(styles,
		{name: "Industria"});

	// Create a map object, and include the MapTypeId to add
	// to the map type control.
	var mapOptions = {
		zoom: 14,
		scrollwheel: false,
		center: new google.maps.LatLng(46.335088, 18.700714),
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
		}
	};
	var map = new google.maps.Map(document.getElementById('map'), mapOptions);

	//Associate the styled map with the MapTypeId and set it to display.
	map.mapTypes.set('map_style', styledMap);
	map.setMapTypeId('map_style');

	var beachMarker = new google.maps.Marker({
		position: {lat: 46.332000, lng: 18.70700},
		map: map,
		icon: "/images/marker.png"
	});

}
if ($('#map').length) {
	initialize();
}